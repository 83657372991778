import React from "react"
import Layout from "../components/layout"
import {graphql, Link} from 'gatsby';
import {GatsbySeo} from 'gatsby-plugin-next-seo/src/meta/gatsby-seo';
import useSiteMetadata from '../hooks/use-site-metadata';

const KitaplarPage = ({data, location}) => {
    const posts = data.allMarkdownRemark.nodes
    const { siteUrl, defaultImage } = useSiteMetadata();

    return (

        <Layout>
            <GatsbySeo
                title="Kitaplar"
                description="Bu kitaplarda 'Allah, Kâinat(evren) ve İnsan' gerçeğini, Tasavvufla(metafizik) ilgili her bir konuyu ayrıntılı olarak; diğer dini ve İslami- felsefi-kelâmi-fıkhi konuları ise kısmen ayrıntılı ve kısmen özet de olsa bulabilirsiniz."
                openGraph={{
                    url: `${siteUrl}${location.pathname}`,
                    title: "Kitaplar",
                    images: [
                        {
                            url: `${siteUrl}${defaultImage}`,
                            width: 1200,
                            height: 630
                        }
                    ]
                }}
            />
            <section id="page-title" className="page-title-center">

                <div className="container">
                    <h1>Kitaplarımız</h1>
                    <span>Kazım Yardımcı'nın eserleri</span>
                    {/*<ol className="breadcrumb">*/}
                    {/*    <li className="breadcrumb-item"><a href="#">Home</a></li>*/}
                    {/*    <li className="breadcrumb-item active" aria-current="page">Blog</li>*/}
                    {/*</ol>*/}
                </div>

            </section>
            <section id="content">
                <div className="content-wrap">
                    <div className="container clearfix">
                        <div id="portfolio" className="portfolio row grid-container gutter-20" data-layout="fitRows">
                            {posts.map(book => {
                                return (
                                    <article className="portfolio-item col-lg-3 col-md-4 col-sm-6 col-12" key={book.frontmatter.slug}>
                                    <div className="grid-inner">
                                        <div className="portfolio-image">
                                            <Link to={book.frontmatter.slug}><img src={book.frontmatter.featuredImage?.childImageSharp.fluid.src} style={{maxHeight:'386px', borderRadius: '3px'}} alt={`${book.frontmatter.title} - ${book.frontmatter.author}`}/></Link>
                                        </div>
                                        <div className="portfolio-desc">
                                            <h3><Link to={book.frontmatter.slug}>{book.frontmatter.title}</Link></h3>
                                            <span>{`${book.frontmatter.author} - ${book.frontmatter.year}`}</span>
                                        </div>
                                    </div>
                                </article>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className="container clearfix topmargin-sm bottommargin-lg">
                    <div className="line line-sm"></div>

                    <p>Bu kitaplarda  ''Allah, Kâinat(evren) ve İnsan'' gerçeğini, Tasavvufla(metafizik) ilgili her bir konuyu ayrıntılı olarak; diğer dini ve İslami- felsefi-kelâmi-fıkhi konuları ise kısmen ayrıntılı ve kısmen özet de olsa bulabilirsiniz.</p>
                    <p>Bu kitaplar ve veriler ticari maksatla yazılmamıştır. Yazara ait bütün kitaplar ve verilerin tamamı, internete  yüklenmiş ve insanlığın istifadesine sunulmuştur. Hiçbir  yazar, kitaplarını ve yazılarını baştan sona internet yolu ile insanlığa sunmamıştır. Bu yazarın kendine özgü bir farkıdır. Yazarımız eserlerinden hiçbir maddi menfaat beklememektedir. Bunu tamamen Allah rızası ve insanlığa olan sevgisinden dolayı yapmaktadır. BU BİR FARKTIR. Ayrıca bu yedi kitabı içeren 4 ciltlik Rufai Külliyatına da  aynı şekilde  maddi bir bedel konmamıştır. Yazarımız bir çok Kütüphane, Üniversite ve kurumlara karşılıksız olarak imzalayıp hediye olarak göndermiştir. Yazarın  ticari ve maddi amacı yoktur. Sn. Yazarımız’ın bu konudaki kesin görüşü şu şekildedir; “Allah'ın Dini ulvidir(Çok yüce ve mukaddestir) madde ve para, sufli- alçaktır. Yüce ve mukaddes olan, alçak olan madde ve para ile değiştirilemez. Kur'an, hadis ve dini kitapları, para ile satan bunlarla geçinen, Allah huzurunda çok şiddetli bir ikapla karşılaşır.”</p>
                </div>

            </section>
        </Layout>
    )
}

export default KitaplarPage

export const pageQuery = graphql`
  query {
      allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/(/content/kitaplar/)/"  }}
          sort: { fields: [frontmatter___no], order: ASC }
      ) {
      nodes {
        frontmatter {
          title
          slug
          year
          author
          featuredImage {
            childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
            }
          }
        }
      }
    }
  }
`